import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDataFromFirebase } from '../../firebase/firebaseFunctions';

export default function Events() {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const eventsPerPage = 9; // Adjust this value to set the number of events per page

  useEffect(() => {
    getDataFromFirebase('events', (fetchedEvents) => {
      setEvents(fetchedEvents);
    });
  }, []);

  // Pagination logic
  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = events.slice(indexOfFirstEvent, indexOfLastEvent);

  const totalPages = Math.ceil(events.length / eventsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <section className='mt-4 p-2'>
        <div className="single-slider">
          <div className="container">
            <div className="row justify-content-between inner-hero" style={{ backgroundImage: "url('/images/about.png')" }}>
              <div className="col-12">
                <h3 className="font-700 mb-0 wow fadeInUp" data-wow-delay="0.0s">Events</h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog-section-three my-4 py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7">
              <div className="section-tittle text-center mb-50">
                <span className="sub-tittle text-capitalize font-600">
                  Upcoming Events
                </span>
                <h2 className="title font-700 text-capitalize">
                  Join Us in <span className="text-red">Making</span> a Difference
                </h2>
                <p className="pera wow fadeInUp" data-wow-delay="0.3s">
                  Stay updated on our latest events, from fundraising drives to community outreach programs. Discover how you can participate and help support Human Concern USA’s mission to bring positive change to those in need.
                </p>
              </div>
            </div>
          </div>

          {/* Conditionally render if there are no events */}
          {events.length === 0 || currentEvents.filter((event) => event.status === "active").length === 0 ? (
            <div className="row justify-content-center">
              <div className="col-xl-7">
                <p className="text-center">
                No upcoming events found. Scroll down to explore our past events.
                </p>
              </div>
            </div>
          ) : (
            <>
              <div className="row donate-section gy-24 gap-x-25 mb-4 pb-4">
                {currentEvents
                  .filter((event) => event.status === "active")
                  .map((event, index) => {
                    return (
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 view-wrapper" key={index}>
                        <div className="single-donate h-calc">
                          <div className="donate-img position-relative">
                            <img
                              className="w-100"
                              src={event.image}
                              alt="Event Thumbnail"
                            />
                          </div>
                          <div className="donate-info">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="donate-info-title w-100">
                                <p className="text-secondary mb-1">{new Date(event.date).toDateString()} {event.time}</p>
                                <h4 className="title text-capitalize mb-1">
                                  {event.title}
                                </h4>
                                <p className="subtitle mb-0">
                                  {event.description}
                                </p>
                                {event.bookingUrl ? (
                                  <a
                                    href={event.bookingUrl}
                                    target="_blank"
                                    className="btn btn-primary text-white donate-btn w-100 mt-15"
                                  >
                                    View Event
                                  </a>
                                ) : (
                                  <button
                                    className="btn btn-primary text-white donate-btn w-100 mt-15"
                                    disabled
                                  >
                                    {event.endText}
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
                
              {/* Pagination */}
              {totalPages > 1 && (
                <nav className="pagination-nav">
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "50px",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    Page {currentPage} of {totalPages}
                  </p>
                  <ul className="pagination">
                    {currentPage > 1 && (
                      <li className="page-item">
                        <button
                          className="page-link next"
                          onClick={() => paginate(currentPage - 1)}
                          aria-label="Previous"
                        >
                          <i className="ri-arrow-left-line"></i>
                        </button>
                      </li>
                    )}
                    {[...Array(totalPages)].map((_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          index + 1 === currentPage ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => paginate(index + 1)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}
                    {currentPage < totalPages && (
                      <li className="page-item">
                        <button
                          className="page-link next"
                          onClick={() => paginate(currentPage + 1)}
                          aria-label="Next"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </button>
                      </li>
                    )}
                  </ul>
                </nav>
              )}
            </>
          )}
        </div>
      </section>


      <section className="blog-section-three my-4 py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7">
              <div className="section-tittle text-center mb-50">
                <span className="sub-tittle text-capitalize font-600">
                  Past Events
                </span>
                <h2 className="title font-700 text-capitalize">
                Together, We Made a <span className="text-red">Difference</span>
                </h2>
                <p className="pera wow fadeInUp" data-wow-delay="0.3s">
                Take a look back at our impactful events, from successful fundraising drives to meaningful community outreach programs. Explore how our collective efforts supported Human Concern USA’s mission to bring positive change to those in need. Thank you for being a part of our journey!
                </p>
              </div>
            </div>
          </div>

          {/* Conditionally render if there are no events */}
          {events.length === 0 ? (
            <div className="row justify-content-center">
              <div className="col-xl-7">
                <p className="text-center">No past events at the moment. Please check back later!</p>
              </div>
            </div>
          ) : (
            <>
              <div className="row donate-section gy-24 gap-x-25 mb-4 pb-4">
                {currentEvents
                  .filter((event) => event.status === "inactive")
                  .map((event, index) => {
                    return (
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 view-wrapper" key={index}>
                        <div className="single-donate h-calc">
                          <div className="donate-img position-relative">
                            <img
                              className="w-100"
                              src={event.image}
                              alt="Event Thumbnail"
                            />
                          </div>
                          <div className="donate-info">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="donate-info-title w-100">
                                <p className="text-secondary mb-1">{new Date(event.date).toDateString()} {event.time}</p>
                                <h4 className="title text-capitalize mb-1">
                                  {event.title}
                                </h4>
                                <p className="subtitle mb-0">
                                  {event.description}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>


              {/* Pagination */}
              {totalPages > 1 && (
                <nav className="pagination-nav">
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "50px",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    Page {currentPage} of {totalPages}
                  </p>
                  <ul className="pagination">
                    {currentPage > 1 && (
                      <li className="page-item">
                        <button
                          className="page-link next"
                          onClick={() => paginate(currentPage - 1)}
                          aria-label="Previous"
                        >
                          <i className="ri-arrow-left-line"></i>
                        </button>
                      </li>
                    )}
                    {[...Array(totalPages)].map((_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          index + 1 === currentPage ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => paginate(index + 1)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}
                    {currentPage < totalPages && (
                      <li className="page-item">
                        <button
                          className="page-link next"
                          onClick={() => paginate(currentPage + 1)}
                          aria-label="Next"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </button>
                      </li>
                    )}
                  </ul>
                </nav>
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
}
